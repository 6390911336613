import React from 'react';
import Roster from '../components/Roster.js';

function Admin() {
  return (
    <>
      <Roster />
    </>
  )
}

export default Admin;