import React from 'react'
import Signup from '../components/Signup'

function Register() {
  return (
    <>
      <Signup />
    </>
  )
}
export default Register
